import * as rest from "./base";

const getListArticles = () => rest.get("/articles");
const getDetailArticle = (id) => rest.get(`/articles/${id}`);
const postArticles = (data) => rest.post("/articles", data);
const deleteArticles = (id) => rest.del(`/articles/${id}`);

export default {
    getListArticles,
    getDetailArticle,
    postArticles,
    deleteArticles
};
