import React, { useEffect, useState } from "react"
import { Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { newsApi } from "apis"
import { HTTP_STATUS } from "constants"
import BlogGrid from "./BlogGrid"
import RightBar from "../BlogList/RightBar"

const Index = props => {
    //meta title
    document.title="News Grid | Speezy- React Admin & Dashboard Template";
  
    const [newsList, setNewsList] = useState([]);

    useEffect(() => {
      async function fetchData() {
        try {
          const response = await newsApi.getListArticles();
          if (response.status === HTTP_STATUS.OK) {
            setNewsList(response.data);
          } 
        } catch (error) {
          console.log("error", error);
        }
      }
      fetchData();
    }, []);
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem="News Grid" />
          <Row>
            <BlogGrid news={newsList?.articles?.reverse() || []}/>
            <RightBar news={newsList || []}/>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Index
