import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap"
import { newsApi } from "apis"
import { HTTP_STATUS } from "constants"
import { convertImagePath } from "helpers/utils";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { format } from 'date-fns';
// import images
import {
  useParams
} from "react-router-dom";

const BlogDetails = () => {
  let params = useParams();
  const { id, slug } = params;
  //meta title
  document.title = "Blog Details | Speezy- React Admin & Dashboard Template";
  const [blog, setBlog] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const {data, status} = await newsApi.getDetailArticle(id);
        if (status === HTTP_STATUS.OK) {
          setBlog(data);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
    if(id) {
      fetchData();
    }
  }, [id]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Blog" breadcrumbItem="Blog Details" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="pt-3">
                    <Row className="justify-content-center">
                      <Col xl={8}>
                        <div>
                          <div className="text-center">
                            <div className="mb-4">
                              {
                                blog?.categories?.map(
                                  (item,index) => (
                                    <Link
                                      key={index}
                                      to="#"
                                      className="badge bg-light font-size-12"
                                    >
                                      <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                                      {`${item}, `}
                                    </Link>
                                  )
                                )
                              }
                            </div>
                            <h4>{blog?.title}</h4>
                            <p className="text-muted mb-4">
                              <i className="mdi mdi-calendar me-1"></i> {blog?.modifiedAt && format(new Date(blog.modifiedAt), 'yyyy-MM-dd')}
                            </p>
                          </div>

                          <hr />
                          <div className="text-center">
                            <Row>
                              <Col sm={4}>
                                <div>
                                  <p className="text-muted mb-2">Categories</p>
                                  {
                                    blog?.categories?.map(
                                      (item,index) => (
                                        <h5 key={index} className="font-size-15">{`${item}, `}</h5>
                                      )
                                    )
                                  }
                                </div>
                              </Col>
                              <Col sm={4}>
                                <div className="mt-4 mt-sm-0">
                                  <p className="text-muted mb-2">Date</p>
                                  <h5 className="font-size-15">{blog?.modifiedAt && format(new Date(blog.modifiedAt), 'yyyy-MM-dd')}</h5>
                                </div>
                              </Col>
                              <Col sm={4}>
                                <div className="mt-4 mt-sm-0">
                                  <p className="text-muted mb-2">Post by</p>
                                  <h5 className="font-size-15">Admin</h5>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <hr />

                          <div className="my-5">
                            <img
                              src={convertImagePath(blog?.thumbnail)}
                              alt=""
                              className="img-thumbnail mx-auto d-block"
                            />
                          </div>

                          <hr />

                          <div className="mt-4">
                            <div id="blog-detail-wiki" className="text-muted font-size-14" dangerouslySetInnerHTML={{ __html: blog?.content }} />
                            <hr />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BlogDetails
