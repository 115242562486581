import React from "react";
import { Navigate } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// //Ecommerce Pages
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts";
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceDetails/index";
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index";
import CartItemDetail from "../pages/Ecommerce/EcommerceOrders/OrderItemCustomSuit";
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index";
import EcommerceCart from "../pages/Ecommerce/EcommerceCart";
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index";
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct";

// Custom Suit
import CustomFabric from '../pages/Ecommerce/CustomSuits/Fabrics';
import AddFabric from '../pages/Ecommerce/CustomSuits/Fabrics/AddFabric';
import AttributeType from '../pages/Ecommerce/CustomSuits/AttributeType';
import AttributeConfig from '../pages/Ecommerce/CustomSuits/AttributeType/AttributeConfig';

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

//Blog
import BlogList from "../pages/Blog/BlogList/index";
import BlogGrid from "../pages/Blog/BlogGrid/index";
import BlogDetails from "../pages/Blog/BlogDetails";
import BlogCreateNew from "pages/Blog/BlogCreateNew";

//Pages
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  //Ecommerce
  { path: "/ecommerce-product-detail/:id", component: <EcommerceProductDetail /> },
  { path: "/ecommerce-products", component: <EcommerceProducts /> },
  { path: "/ecommerce-orders", component: <EcommerceOrders /> },
  { path: "/ecommerce-orders/custom-suit-detail", component: <CartItemDetail /> },
  { path: "/users-list", component: <EcommerceCustomers /> },
  { path: "/ecommerce-cart", component: <EcommerceCart /> },
  { path: "/ecommerce-checkout", component: <EcommerceCheckout /> },
  { path: "/ecommerce-shops", component: <EcommerceShops /> },
  { path: "/ecommerce-add-product", component: <EcommerceAddProduct /> },

  //Custom suit
  { path: "/custom-suit/fabric", component: <CustomFabric /> },
  { path: "/custom-suit/add-fabric", component: <AddFabric /> },
  { path: "/attribute-types/jacket", component: <AttributeType /> },
  { path: "/attribute-types/trouser", component: <AttributeType /> },
  { path: "/attribute-types/waistcoat", component: <AttributeType /> },
  { path: "/attribute-types/jacket/:id/attribute-configs", component: <AttributeConfig /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },

    //Blog
    { path: "/news-list", component: <BlogList /> },
    { path: "/news-grid", component: <BlogGrid /> },
    { path: "/news-details/:slug/:id", component: <BlogDetails /> },
    { path: "/news-create-new", component: <BlogCreateNew /> },
  
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
];

export { authProtectedRoutes, publicRoutes };
