import React, { useState } from 'react';
import styles from './index.module.css';
import { convertPartImage, convertImagePath } from '../../../helpers/utils';
import { useLocation } from 'react-router-dom';

const CartItemDetail = () => {
  const location = useLocation();
  const { customItemDetail } = location.state || {};
  const [isDetailJacket, setIsDetailJacket] = useState(4);
  const [isDetailTrouser, setIsDetailTrouser] = useState(4);
  const [isDetailWaistcoat, setIsDetailWaistcoat] = useState(4);
  
  let selectedJacketCustom = [];
  let selectedTrouserCustom = [];
  let selectedWaistcoatCustom = [];
  let selectedFabric = {};

  if(customItemDetail) {
    selectedFabric = customItemDetail?.customConfig?.fabric;
    if(customItemDetail?.product?.name === 'Jacket') {
        selectedJacketCustom = customItemDetail?.customConfig?.item;
    } else if(customItemDetail?.product?.name === 'Trousers') {
        selectedTrouserCustom = customItemDetail?.customConfig?.item;
    } else if(customItemDetail?.product?.name === 'Waistcoat') {
        selectedWaistcoatCustom = customItemDetail?.customConfig?.item;
    }
  };

  const selectedJacketComponents = selectedJacketCustom?.filter(item => item?.type !== 'style');

  return (
    <div className={styles.container}>
      <div className={styles.bigTitle}>Your custom suit summary</div>
      <div className={styles.content}>
        {
          selectedJacketCustom?.length > 0 && (
            <div className={styles.contentItem}>
              <div className={styles.leftContent}>
                {
                  selectedJacketCustom && selectedJacketCustom?.filter(
                    it => !it?.isNotApplyCustom
                  )?.map((item, index) => {                
                    const path = convertPartImage(selectedFabric?.id, item?.id, item?.dependencyId);
                    if(item?.type === 'Closure') {
                      return (
                        <img
                          src={path}
                          key={index} 
                          alt={item.name}
                        />
                      )
                    } else {
                      return (
                        <img
                          src={path}
                          key={index} 
                          alt={item.name}
                          className={item?.type ? `custom-suit-fabric-collar ${item.type}` : "custom-suit-fabric-collar"}
                        />
                      )
                    }        
                  })
                }
              </div>
              <div className={styles.rightContent}>
                <div className={styles.contentRightItem}>
                  <div className={styles.title}>
                    <p>Jacket</p>
                  </div>
                  <div className={styles.optionItem}>
                    {
                      selectedJacketComponents && selectedJacketComponents?.map((item, index) => {
                        if(index < isDetailJacket) {
                          return (
                            <div className={styles.colorss} key={index}>
                              <div className={styles.colorItems}>
                                <img
                                  src={convertImagePath(item?.images)}
                                  alt={item.name}
                                  style={{objectFit: 'cover'}}
                                />
                              </div>
                              <div className={styles.item}>
                                <p className='font-semibold'>{item.attributeType.name}</p>
                                <p className={styles.description}>{item.name}</p>
                              </div>
                            </div>
                          )
                        }
                      })
                    }
                  </div>
                  <button className={styles.seeAllDetail} onClick={() => {
                      if(isDetailJacket === 4) {
                        setIsDetailJacket(selectedJacketComponents?.length);
                      } else {
                        setIsDetailJacket(4);
                      }
                    }}>
                    See all details
                  </button>
                </div>
              </div>
            </div>
          )
        }
        {
          selectedTrouserCustom?.length > 0 && (
            <div className={styles.contentItem}>
              <div className={styles.leftContent}>
                {
                  selectedTrouserCustom && selectedTrouserCustom?.filter(
                    it => !it?.isNotApplyCustom
                  )?.map((item, index) => {                
                    const path = convertPartImage(selectedFabric?.id, item?.id, item?.dependencyId);
                    if(item?.type === 'Closure') {
                      return (
                        <img
                          src={path}
                          key={index} 
                          alt={item.name}
                        />
                      )
                    } else {
                      return (
                        <img
                          src={path}
                          key={index} 
                          alt={item.name}
                          className={item?.type ? `custom-suit-fabric-collar ${item.type}` : "custom-suit-fabric-collar"}
                        />
                      )
                    }        
                  })
                }
              </div>
              <div className={styles.rightContent}>
                <div className={styles.contentRightItem}>
                  <div className={styles.title}>
                    <p>Trousers</p>
                  </div>
                  <div className={styles.optionItem}>
                    {
                      selectedTrouserCustom && selectedTrouserCustom?.map((item, index) => {
                        if(index < isDetailTrouser) {
                          return (
                            <div className={styles.colorss} key={index}>
                              <div className={styles.colorItems}>
                                <img
                                  src={convertImagePath(item?.images)}
                                  alt={item.name}
                                  style={{
                                    objectFit: 'cover'
                                  }}
                                />
                              </div>
                              <div className={styles.item}>
                                <p className='font-semibold'>{item?.attributeType?.name}</p>
                                <p className={styles.description}>{item.name}</p>
                              </div>
                            </div>
                          )
                        }
                      })
                    }
                  </div>
                  <button className={styles.seeAllDetail} onClick={() => {
                      if(isDetailTrouser === 4) {
                        setIsDetailTrouser(selectedTrouserCustom?.length);
                      } else {
                        setIsDetailTrouser(4);
                      }
                    }}>
                    See all details
                  </button>
                </div>
              </div>
            </div>
          )
        }
        {
          selectedWaistcoatCustom?.length > 0 && (
            <div className={styles.contentItem}>
               <div className={styles.leftContent}>
              {
                selectedWaistcoatCustom?.filter(
                  it => !it?.isNotApplyCustom
                )?.map((item, index) => {                
                  const path = convertPartImage(selectedFabric?.id, item?.id, item?.dependencyId);
                  if(item?.type === 'Closure') {
                    return (
                      <img
                        src={path}
                        key={index} 
                        alt={item.name}
                      />
                    )
                  } else {
                    return (
                      <img
                        src={path}
                        key={index} 
                        alt={item.name}
                        className={item?.type ? `custom-suit-fabric-collar ${item.type}` : "custom-suit-fabric-collar"}
                      />
                    )
                  }        
                })
              }
              </div>
              <div className={styles.rightContent}>
                <div className={styles.contentRightItem}>
                  <div className={styles.title}>
                    <p>Waistcoats</p>
                  </div>
                  <div className={styles.optionItem}>
                  {
                    selectedWaistcoatCustom?.map((item, index) => {
                      if(index < isDetailWaistcoat) {
                        return (
                          <div className={styles.colorss} key={index}>
                            <div className={styles.colorItems}>
                              <img
                                src={convertImagePath(item?.images)}
                                alt={item.name}
                                style={{
                                    objectFit: 'cover'
                                }}
                              />
                            </div>
                            <div className={styles.item}>
                              <p className='font-semibold'>{item?.attributeType?.name}</p>
                              <p className={styles.description}>{item.name}</p>
                            </div>
                          </div>
                        )
                      }
                    })
                  }
                  </div>
                  <button className={styles.seeAllDetail} onClick={() => {
                      if(isDetailWaistcoat === 4) {
                        setIsDetailWaistcoat(selectedTrouserCustom?.length);
                      } else {
                        setIsDetailWaistcoat(4);
                      }
                    }}>
                    See all details
                  </button>
                </div>
              </div>
            </div>
          )
        }
        </div>
    </div>
  )
}

export default CartItemDetail;