import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useLocation } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { Card, CardBody, CardTitle, Col, Container, FormGroup, Form, Input, Label, Nav, NavItem, NavLink, Row } from "reactstrap";
import classnames from "classnames";
import { isEmpty, set } from "lodash";
import { HTTP_STATUS } from "../../constants";
import { productApi, cateApi } from "../../apis";
import { convertImagePath } from '../../helpers/utils';
import Select from "react-select";

//Import Star Ratings
import StarRatings from "react-star-ratings";

// RangeSlider
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import data
import { discountData, productsData } from "common/data";

//Import actions
import { getProducts as onGetProducts } from "store/e-commerce/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { handleSearchData } from "components/Common/searchFile";
import Paginations from "components/Common/Pagination";
import Spinners from "components/Common/Spinner";

const priceList = [
  {
    value: 'none',
    label: 'None',
  },
  {
    value: 'lowest',
    label: 'Lowest Price'
  },
  {
    value: 'highest',
    label: 'Highest Price'
  }
]
const EcommerceProducts = () => {

  //meta title
  document.title = "Products | Sprezzy - React Admin & Dashboard Template";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const EcommerenceProductsProperties = createSelector(
    (state) => state.ecommerce,
    (Ecommerce) => ({
      products: Ecommerce.products,
      loading: Ecommerce.loading
    })
  );

  const {
    products, loading
  } = useSelector(EcommerenceProductsProperties);
  const location = useLocation();
  const state = location.state;
  const [productsList, setProductsList] = useState([]);
  const [cateList, setCateList] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [selectedCate, setSelectedCate] = useState(state?.cate || null);
  const [isLoading, setLoading] = useState(loading);
  const [productList, setProductList] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [priceSelected, setPriceSelected] = useState(priceList[0]);

  useEffect(() => {
    dispatch(onGetProducts());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(productsList)) setProductsList(productsList);
  }, [productsList]);

  useEffect(() => {
    if(searchKey !== "") {
      setProductsList(
        productsList?.filter((product) => product?.name?.toLowerCase().includes(searchKey?.toLowerCase()))
      );
    } else {
      if(selectedCate) {
        handleGetProductsCate(selectedCate);
      } else {
        fetchProds();
      }
    }
  }, [searchKey]);

  useEffect(() => {
    if(priceSelected?.value !== 'None') {
      if(priceSelected?.value === 'lowest') {
        setProductsList(productsList?.sort((a, b) => a.price - b.price));
      } else {
        setProductsList(productsList?.sort((a, b) => b.price - a.price));
      }
    } else {
      if(selectedCate) {
        handleGetProductsCate(selectedCate);
      } else {
        fetchProds();
      }
    }
  }, [priceSelected])

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const onSelectDiscount = e => {
    const { value } = e.target;

    if (value !== null) {
      const filteredProducts = (products || [])?.filter((product) => product.offer.toString() === value.toString());
      setProductList(filteredProducts);
    } else {
      setProductList(products);
    }
  };

  //Product Filter with noUi slider
  const [minCost, setMinCost] = useState(0);
  const [maxCost, setMaxCost] = useState(500);

  const onUpdate = useCallback((value) => {
    const filterData = (productsList || [])?.filter((i) => {
      return i.price >= minCost && i.newPrice <= maxCost
    })
    setProductList(filterData)
    setMinCost(value[0]);
    setMaxCost(value[1]);
  }, [minCost, maxCost, products]);

  useEffect(() => {
    onUpdate([minCost, maxCost]);
  }, [minCost, maxCost, onUpdate]);

  /*
  on change rating checkbox method
  */
  const onChangeRating = value => {
    if (value !== null) {
      const filteredProducts = (products || [])?.filter((product) => product.rating === value);
      setProductList(filteredProducts);
    } else {
      setProductList(products);
    }
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const perPageData = 6;
  const indexOfLast = currentPage * perPageData;
  const indexOfFirst = indexOfLast - perPageData;
  const currentdata = useMemo(() => products?.slice(indexOfFirst, indexOfLast), [products, indexOfFirst, indexOfLast])

  useEffect(() => {
    setProductList(currentdata);
  }, [currentdata, productsData]);

  // search
  const handleSearch = (ele) => {
    const query = ele.value.toLowerCase();
    setSearchKey(query);
  }

  useEffect(() => {
    async function fetchCates() {
      try {
        const { data, status } = await cateApi.getCate();        
        if (status === HTTP_STATUS.OK) {
          setCateList(data);
        }
      } catch (error) {
        console.log('get attribute error: ', error);
      }
    }
    fetchCates();
  }, []);

  async function fetchProds() {
    try {
      const { data, status } = await productApi.getProducts();        
      if (status === HTTP_STATUS.OK) {
        setProductsList(data?.products);
      }
    } catch (error) {
      console.log('get attribute error: ', error);
    }
  }

  useEffect(() => {
    fetchProds();
  }, []);

  const handleGetProductsCate = async (cloth) => {
    try {
      const { data, status } = await cateApi.getProductCate(cloth?.id, 1, 1000);        
      if (status === HTTP_STATUS.OK) {
        const data_ = data?.products?.map((item) => {
          return {
            ...item,
            categories: [cloth]
          }
        });
        setProductsList(data_);
      }
    } catch (error) {
      console.log('get attribute error: ', error);
    }
  };

  const handleDeleteProduct = async (item) => {
    try {
      if(selectedCate) {
        await cateApi.deleteProductCate(selectedCate?.id, item?.id);
      }

      for (let i = 0; i < item?.photos; i++) {
        await productApi.deleteProductImage(item?.id, item?.photos?.[index]);
      }
      const { status } = await productApi.deleteProduct(item.id);      
      if(status === HTTP_STATUS.OK) {
        const newProducts = productsList.filter(product => product.id !== item.id);
        setProductsList(newProducts);
        window.alert("Delete product successfully!");
      } else {
        window.alert("Delete product failed!");
      }
    } catch (error) {
      window.alert("Delete product failed!");
      console.log('delete product error: ', error);
    }
  };
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="List Products" />
          <Row>
            <Col lg="3">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Filter</CardTitle>
                  <div>
                    <h5 className="font-size-14 mb-3">{selectedCate?.name || 'Clothes'}</h5>
                    {/* Render Cloth Categories */}
                    <ul className="list-unstyled product-list">
                      {(cateList || [])?.map((cloth, key) => (
                        <li key={"_li_" + key}>
                          <Link onClick={() => {
                            setSelectedCate(cloth)
                            handleGetProductsCate(cloth);
                          }}>
                            <i className="mdi mdi-chevron-right" /> {cloth.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-4 pt-3">
                    <h5 className="font-size-14 mb-3">Price</h5>
                    <br />
                    <div>
                      <Select 
                        name="price" 
                        options={priceList} 
                        className="select2"
                        onChange={(selectedOption) => {
                          setPriceSelected(selectedOption);
                        }} 
                        defaultValue={priceSelected}
                        value={priceSelected}
                      />
                    </div>
                  </div>

                  <div className="mt-4 pt-3">
                    <h5 className="font-size-14 mb-3">Discount</h5>
                    {(discountData || []).map((discount, i) => (
                      <FormGroup check className="mt-2" key={i}>
                        <Input type="checkbox" value={discount.value} onChange={onSelectDiscount} />
                        <Label check>{discount.label} </Label>
                      </FormGroup>
                    ))}
                  </div>

                  <div className="mt-4 pt-3">
                    <h5 className="font-size-14 mb-3">Customer Rating</h5>
                    <div>
                      <FormGroup check className="mt-2">
                        <Input type="checkbox" id="productratingCheck1" onChange={(e) => { onChangeRating(4) }} />
                        <Label check htmlFor="productratingCheck1">
                          4 <i className="bx bx-star text-warning" /> & Above
                        </Label>
                      </FormGroup>
                      <FormGroup check className="mt-2">
                        <Input type="checkbox" id="productratingCheck2" onChange={(e) => { onChangeRating(3) }} />
                        <Label check htmlFor="productratingCheck2">
                          3 <i className="bx bx-star text-warning" /> & Above
                        </Label>
                      </FormGroup>
                      <FormGroup check className="mt-2">
                        <Input type="checkbox" id="productratingCheck3" onChange={(e) => { onChangeRating(2) }} />
                        <Label check htmlFor="productratingCheck3">
                          2 <i className="bx bx-star text-warning" /> & Above
                        </Label>
                      </FormGroup>
                      <FormGroup check className="mt-2">
                        <Input type="checkbox" id="productratingCheck4" onChange={(e) => { onChangeRating(1) }} />
                        <Label check htmlFor="productratingCheck4">
                          1 <i className="bx bx-star text-warning" />
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={9}>
              <Row className="mb-3">
                <Col xl={4} sm={6}>
                  <div className="mt-2" style={{ textAlign: 'left' }}>
                    <h5>{selectedCate?.name || 'Clothes'}</h5>
                  </div>
                </Col>
                <Col lg={8} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Form className="mt-4 mt-sm-0 float-sm-end d-sm-flex align-items-center">
                    <div className="search-box me-2">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="form-control border-0"
                          placeholder="Search..."
                          onChange={(e) => handleSearch(e.target)}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                    <Nav className="product-view-nav justify-content-end mt-3 mt-sm-0" pills>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            toggleTab("1");
                          }}
                        >
                          <i className="bx bx-grid-alt" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            toggleTab("2");
                          }}
                        >
                          <i className="bx bx-list-ul" />
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Form>
                </Col>
              </Row>

              {
                isLoading ? <Spinners setLoading={setLoading} />
                  :
                  <>
                    <Row>
                      {!isEmpty(productsList) &&
                        (productsList?.filter(
                          product => product?.name !== "Jacket" && product?.name !== "Trousers" && product?.name !== "Waistcoat"
                        ) || [])?.map((product, key) => {
                          const photo = product?.photos?.[product?.photos?.length - 1]?.path;
                          return (
                            <Col xl={4} sm={6} key={"_col_" + key}>
                              <Card>
                                <CardBody>
                                  <div className="product-img position-relative">
                                    {product.isOffer ? (
                                      <div className="avatar-sm product-ribbon">
                                        <span className="avatar-title rounded-circle bg-primary">
                                          {`- ${product.offer} %`}
                                        </span>
                                      </div>
                                    ) : null}
                                    <Link
                                      to={"/ecommerce-product-detail/" + product.id}
                                      className="text-dark"
                                      >
                                      <img
                                        style={{ height: "auto"  }}
                                        src={convertImagePath(photo)}
                                        alt=""
                                        className="img-fluid mx-auto d-block"
                                      />
                                    </Link>
                                  </div>
                                  <div className="mt-4 text-center">
                                    <h5 className="mb-3 text-truncate">
                                    <Link
                                      to={"/ecommerce-product-detail/" + product.id}
                                      className="text-dark">
                                      {product.name}
                                    </Link>
                                    </h5>
                                    <div className="text-muted mb-3">
                                      <StarRatings
                                        rating={product.rating}
                                        starRatedColor="#F1B44C"
                                        starEmptyColor="#74788d"
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="14px"
                                        starSpacing="1px"
                                      />
                                    </div>
                                    <h5 className="my-0">
                                      {/* <span className="text-muted me-2">
                                        <del>${product.oldPrice}</del>
                                      </span> */}
                                      <b>${product.price}</b>
                                    </h5>
                                    <h5 className="mt-2">
                                      <span>{product.stock} in stock</span>
                                    </h5>
                                    <div style={{
                                      marginTop: '10px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      gap: '10px',
                                      cursor: 'pointer'
                                    }}>
                                      <i className="fas fa-trash" onClick={
                                        () => {
                                          if (window.confirm("Do you really want to delete product?")) {
                                            handleDeleteProduct(product)
                                          }
                                        }
                                      }></i>
                                      <i className="fas fa-edit" onClick={() => {
                                        navigate(`/ecommerce-add-product`, {
                                          state: { item: product }
                                        })
                                      }}></i>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          )
                        })}
                    </Row>
                    <Paginations
                      perPageData={perPageData}
                      data={productsList?.filter(
                        product => product?.name !== "Jacket" && product?.name !== "Trousers" && product?.name !== "Waistcoat"
                      )}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      isShowingPageLength={false}
                      paginationDiv="col-lg-12"
                      paginationClass="pagination pagination-rounded justify-content-center mt-3 mb-4 pb-1"
                    />
                  </>
              }

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

EcommerceProducts.propTypes = {
  products: PropTypes.array,
  onGetProducts: PropTypes.func,
};

export default withRouter(EcommerceProducts);
