export default [
    {
        value: 'pending',
        label: "Pending",
    },
    {
        value: 'failed',
        label: "Failed",
    },
    {
        value: 'confirmed',
        label: "Confirmed",
    },
    {
        value: 'open',
        label: "Open",
    },
    {
        value: 'cancelled',
        label: "Cancelled",
    },
    {
        value: 'delivered',
        label: "Delivered",
    },
    {
        value: 'refunded',
        label: "Refunded",
    },
    {
        value: 'paid',
        label: "Paid",
    }
]