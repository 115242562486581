import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { orderApi } from '../../apis';
import { HTTP_STATUS, STATUS } from "../../constants";
import { Badge, Button, Card, CardBody, Form, Input, Label, FormGroup } from "reactstrap";
import { format } from 'date-fns';
import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal";


import TableContainer from "../../components/Common/TableContainer";
import { Link } from "react-router-dom";

const LatestTranaction = () => {
  const [orders, setOrders] = useState([]);
  const [modal1, setModal1] = useState(false);
  const toggleViewModal = () => setModal1(!modal1);
  const [orderId, setOrderId] = useState(null);

  useEffect(() => {
    async function fetchDataOrder() {
      try {
        const { data, status } = await orderApi.getOrders();
        if (status === HTTP_STATUS.OK) {
          let orders = [...data];
          orders = orders?.filter(it => it?.items?.length > 0)?.map((order) => {
            const { items } = order;
            const totalPrice = items?.reduce((acc, item) => acc + item.price, 0);
            return {
              id: order.id,
              orderId: order.id,
              billingName: order.fullName,
              orderDate: format(new Date(order.modifiedAt), 'yyyy-MM-dd HH:mm:ss'),
              total: totalPrice,
              paymentStatus: STATUS.find(item => item.value === order.status)?.label || 'Pending',
              paymentMethod: 'Cash',
              badgeClass: "danger",
            }
          });
          setOrders(orders?.reverse()?.filter((item, index) => index < 5) || []);
        }
      } catch (error) {
        console.log('error get orders: ', error);
      }
    };
    fetchDataOrder();
  }, [JSON.stringify(orders)]);

  const convertStatusColor = (value) => {
    if(value === "Paid") {
      return "success";
    } else if(value === "Cancelled" || value === "Failed") {
      return "danger";
    } else if(value === "Pending" || value === "Confirmed") {
      return "primary"
    } else if(value === "Open") {
      return "info"
    } else if(value === "Delivered" || value === "Refunded") {
      return "warning"
    }
  }

  const columns = useMemo(
    () => [
      {
        header: 'Order ID',
        accessorKey: 'orderId',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return <Link to="#" className="text-body fw-bold">{cellProps.row.original.orderId}</Link>
        }
      },
      {
        header: 'Billing Name',
        accessorKey: 'billingName',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Date',
        accessorKey: 'orderDate',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Total',
        accessorKey: 'total',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return `$ ${cellProps.row.original.total}`;
        }
      },
      {
        header: 'Payment Status',
        accessorKey: 'paymentStatus',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return <Badge
            className={"font-size-12 badge-soft-" + convertStatusColor(cellProps.row.original.paymentStatus)}
          >
            {cellProps.row.original.paymentStatus}
          </Badge>;
        }
      },
      {
        header: 'Payment Method',
        accessorKey: 'paymentMethod',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return <span>
            <i
              className={
                (cellProps.row.original.paymentMethod === "Paypal" ? "fab fa-cc-paypal me-1" : "" ||
                  cellProps.row.original.paymentMethod === "COD" ? "fab fas fa-money-bill-alt me-1" : "" ||
                    cellProps.row.original.paymentMethod === "Mastercard" ? "fab fa-cc-mastercard me-1" : "" ||
                      cellProps.row.original.paymentMethod === "Visa" ? "fab fa-cc-visa me-1" : ""
                )}
            />{" "}
            {cellProps.row.original.paymentMethod}
          </span>;
        }
      },
      {
        header: 'View Details',
        cell: (cellProps) => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                toggleViewModal();
                setOrderId(cellProps.row.original.orderId);
              }}
            >
              View Details
            </Button>);
        }
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <EcommerceOrdersModal id={orderId} isOpen={modal1} toggle={toggleViewModal} />
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">Latest Transaction</div>

          <TableContainer
            columns={columns}
            data={orders}
            isGlobalFilter={false}
            tableClass="align-middle table-nowrap mb-0"
            theadClass="table-light"
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

LatestTranaction.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(LatestTranaction);
