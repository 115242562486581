import React, { useEffect, useState } from "react"
import { Container, Row } from "reactstrap"
import { newsApi } from "apis"
import { HTTP_STATUS } from "constants"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import BlogList from "./BlogList"
import RightBar from "./RightBar"

const Index = () => {
  //meta title
  document.title="New List | Speezy- React Admin & Dashboard Template";

  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await newsApi.getListArticles();
        if (response.status === HTTP_STATUS.OK) {
          setNewsList(response.data);
        } 
      } catch (error) {
        console.log("error", error);
      }
    }
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem="News List" />
          <Row>
            <BlogList news={newsList?.articles?.reverse() || []}/>
            <RightBar news={newsList || []} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Index
