export const convertImagePath = (imagePath) => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const path = `${baseURL}/images?filePath=${imagePath}`;
    return path;
}

export const convertPartImage = (fabricId, attributeConfigId, dependentAttributeConfigId, secondaryDependentAttributeConfigId) => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    let path = `${baseURL}/attribute-part-image/part-image?fabricId=${fabricId}&attributeConfigId=${attributeConfigId}`;
    if (dependentAttributeConfigId) {
        path += `&dependentAttributeConfigId=${dependentAttributeConfigId}`;
    };
    if (secondaryDependentAttributeConfigId) {
        path += `&secondaryDependentAttributeConfigId=${secondaryDependentAttributeConfigId}`;
    }

    return path;
}