import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { Button, Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane } from "reactstrap";
import classnames from "classnames";
import { isEmpty } from "lodash";
import { format } from 'date-fns';

//Import Star Ratings
import StarRatings from "react-star-ratings";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import { productApi } from '../../../apis';
import { HTTP_STATUS } from "../../../constants";
import { convertImagePath } from '../../../helpers/utils';

const EcommerceProductDetail = props => {
  const productId = props.router.params.id;
  const [product, setProduct] = useState(null);
  //meta title
  document.title = "Product Details | Sprezzy - React Admin & Dashboard Template";

  useEffect(() => {
    async function fetchProduct() {
      try {
        const { data, status } = await productApi.getProductDetail(productId);
        if (status === HTTP_STATUS.OK) {
          setProduct(data);
        }
      } catch (error) {
        console.log('error get orders: ', error);
      }
    };
    fetchProduct();
  }, [productId]);

  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const imageShow = (img, id) => {
    const expandImg = document.getElementById("expandedImg" + id);
    expandImg.src = img;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Product Detail" />
          {!isEmpty(product) && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl={6}>
                        <div className="product-detai-imgs">
                          <Row>
                            <Nav className="flex-column" pills>
                              {
                                product?.photos?.map((photo, i) => {
                                  return (
                                    <Col key={i} xl="12">
                                      <NavItem>
                                        <NavLink className={classnames({ active: activeTab === i+1, })} onClick={() => { toggleTab(i+1); }}>
                                          <img src={convertImagePath(photo?.path)} alt=""
                                            onClick={() => {
                                              imageShow(convertImagePath(photo?.path), i+1);
                                            }} className="img-fluid mx-auto d-block rounded"
                                          />
                                        </NavLink>
                                      </NavItem>
                                    </Col>
                                  )
                                })
                              }
                            </Nav>
                          </Row>
                        </div>
                      </Col>

                      <Col xl="6">
                        <div className="mt-4 mt-xl-3">
                          {product?.categories?.map((category, i) => (
                              <>
                                <Link to="#" className="text-primary">
                                  {category?.name}
                                </Link>
                              </>
                          ))}
                          <h4 className="mt-1 mb-3">{product?.name}</h4>

                          <div className="text-muted float-start me-3">
                            <StarRatings
                              rating={4}
                              starRatedColor="#F1B44C"
                              starEmptyColor="#74788d"
                              numberOfStars={5}
                              name="rating"
                              starDimension="14px"
                              starSpacing="3px"
                            />
                          </div>

                          {!!product?.stock && (
                            <h6 className="text-success text-uppercase">
                              {product?.stock} in stock
                            </h6>
                          )}
                          <h5 className="mb-4">
                            Price :{" "}
                            {/* <span className="text-muted me-2">
                              <del>${product?.price} USD</del>
                            </span>{" "} */}
                            <b>${product?.price} USD</b>
                          </h5>
                          <p className="text-muted mb-4">
                            {product?.description}
                          </p>
                          <Row className="mb-3">
                            <Col md="6">
                              {product?.features &&
                                product?.features.map((item, i) => (
                                  <div key={i}>
                                    <p className="text-muted">
                                      <i
                                        className={classnames(
                                          item.icon,
                                          "font-size-16 align-middle text-primary me-2"
                                        )}
                                      />
                                      {item.type && `${item.type}: `}
                                      {item.value}
                                    </p>
                                  </div>
                                ))}
                            </Col>
                            <Col md="6">
                              {product?.features &&
                                product?.features.map((item, i) => (
                                  <div key={i}>
                                    <p className="text-muted">
                                      <i
                                        className={classnames(
                                          item.icon,
                                          "font-size-16 align-middle text-primary me-2"
                                        )}
                                      />
                                      {item.type && `${item.type}:`}
                                      {item.value}
                                    </p>
                                  </div>
                                ))}
                            </Col>
                          </Row>

                          <div className="product-color">
                            <h5 className="font-size-15">Created at: {product?.createdAt && format(new Date(product.createdAt), 'yyyy-MM-dd HH:mm:ss')}</h5>
                            <h5 className="font-size-15">Modify at: {product?.modifiedAt && format(new Date(product.modifiedAt), 'yyyy-MM-dd HH:mm:ss')}</h5>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

EcommerceProductDetail.propTypes = {
  product: PropTypes.object,
  match: PropTypes.any,
  onGetProductDetail: PropTypes.func,
};

export default withRouter(EcommerceProductDetail);
