import React , {useState, useEffect} from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap"
import { useNavigate } from "react-router-dom";
import { orderApi } from '../../../apis';
import { HTTP_STATUS } from "../../../constants";
import { convertPartImage, convertImagePath } from '../../../helpers/utils';

const EcommerceOrdersModal = props => {
  const navigate = useNavigate();
  const { isOpen, toggle, id } = props;
  const [order, setOrder] = useState(null);

  useEffect(() => {
    async function fetchDataOrder() {
      try {
        const { data, status } = await orderApi.getOrder(id);
        if (status === HTTP_STATUS.OK) {
          setOrder(data);
        }
      } catch (error) {
        console.log('error get orders: ', error);
      }
    };
    fetchDataOrder();
  }, [id]);

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Order Details</ModalHeader>
        {
          order && (
            <ModalBody>
              <p className="mb-2">
                Product id: <span className="text-primary">#{order?.id}</span>
              </p>
              <p className="mb-4">
                Billing Name: <span className="text-primary">{order?.fullName}</span>
              </p>
    
              <div className="table-responsive">
                <Table className="table align-middle table-nowrap">
                  <thead>
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      order?.items?.map((item, index) => {
                        console.log('item: ', item);
                        let customSuit = false;
                        let name = item?.product?.name;
                        let photo;
                        if(name === 'Jacket') {
                          name = 'Custom Made Jacket';
                          customSuit = true;
                          const selectedFabric = item?.customConfig?.fabric;
                          const shoulderItem = item?.customConfig?.item?.find(item => item.type === 'shoulder');
                          photo = convertPartImage(selectedFabric?.id, shoulderItem?.id, shoulderItem?.dependencyId);
                        } else if(name === 'Trousers') {
                          name = 'Custom Made Trousers';
                          customSuit = true;
                          const selectedFabric = item?.customConfig?.fabric;
                          const pleatItem = item?.customConfig?.item?.find(item => item.type === 'Pleat');
                          photo = convertPartImage(selectedFabric?.id, pleatItem?.id, pleatItem?.dependencyId);
                        } else if(name === 'Waistcoat') {
                          name = 'Custom Made Waistcoat';
                          customSuit = true;
                          const selectedFabric = item?.customConfig?.fabric;
                          const sideItem = item?.customConfig?.item?.find(item => item.type === 'Side');
                          photo = convertPartImage(selectedFabric?.id, sideItem?.id, sideItem?.dependencyId);
                        } else {
                          customSuit = false;
                          photo = convertImagePath(item?.product?.photos?.[item?.product?.photos?.length - 1]?.path);
                        };
    
                        return (
                          <tr key={index}>
                            <th scope="row">
                              <div>
                                <img src={photo} alt="" className="avatar-sm" onClick={
                                  () => {
                                    if(customSuit) {
                                      navigate("/ecommerce-orders/custom-suit-detail",
                                        { state: { customItemDetail: item } }
                                      );
                                    } else {
                                      navigate(`/ecommerce-product-detail/${item?.product?.id}`);
                                    }
                                  }
                                } />
                              </div>
                            </th>
                            <td>
                              <div>
                                <h5 className="text-truncate font-size-14">{name}</h5>
                                <p className="text-muted mb-0">$ {item?.product?.price} x 1</p>
                              </div>
                            </td>
                            <td>$ {item?.product?.price}</td>
                          </tr>
                        )})
                    }
                    {/* <tr>
                      <th scope="row">
                        <div>
                          <img src={img7} alt="" className="avatar-sm" onClick={
                            () => {
                              navigate("/ecommerce-orders/custom-suit-detail");
                            }
                          } />
                        </div>
                      </th>
                      <td>
                        <div>
                          <h5 className="text-truncate font-size-14">Wireless Headphone (Black)</h5>
                          <p className="text-muted mb-0">$ 225 x 1</p>
                        </div>
                      </td>
                      <td>$ 255</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div>
                          <img src={img4} alt="" className="avatar-sm" />
                        </div>
                      </th>
                      <td>
                        <div>
                          <h5 className="text-truncate font-size-14">Hoodie (Blue)</h5>
                          <p className="text-muted mb-0">$ 145 x 1</p>
                        </div>
                      </td>
                      <td>$ 145</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <h6 className="m-0 text-right">Sub Total:</h6>
                      </td>
                      <td>
                        $ 400
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <h6 className="m-0 text-right">Shipping:</h6>
                      </td>
                      <td>
                        Free
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <h6 className="m-0 text-right">Total:</h6>
                      </td>
                      <td>
                        $ 400
                      </td>
                    </tr> */}
                  </tbody>
                </Table>
              </div>
            </ModalBody>
          )
        }
        <ModalFooter>
          <Button type="button" color="secondary" onClick={() => {
            toggle();
            setOrder(null)
          }}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

EcommerceOrdersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default EcommerceOrdersModal
